import { useState, useEffect, useCallback, useRef, useMemo, ChangeEvent } from 'react'
import { useParams, useHistory } from 'react-router'
import { Editor, EditorHeader, Suggestion, TitleInput } from '../component'
import { EditorState, convertFromRaw } from 'draft-js'
import { getEditorSelection } from '../component/editor/util'
import { debounce, apiCall } from '../util'
import { useGetWindowSize } from '../hooks'
import { DESKTOP_BREAKPOINT } from '../component/suggestion/constant'
import createToolbarPlugin from '@draft-js-plugins/static-toolbar'
const toolbarPlugin = createToolbarPlugin()

const EditorPage = () => {
  const history = useHistory()
  const { id } = useParams<Record<string, string | undefined>>()
  const [title, setTitle] = useState('')
  const [showWarning, setShowWarning] = useState(false)
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

  // check auth
  useEffect(() => {
    if (!id) return
    const onSuccess = (data: any) => {
      const { title, content } = data
      const contentState = convertFromRaw(JSON.parse(content))
      setTitle(title)
      setEditorState(EditorState.createWithContent(contentState))
    }
    const onError = () => {
      history.push('/editor')
    }
    apiCall({ url: `notebook/${id}`, onSuccess, onError })
  }, [id, history])

  // data fetching
  const [isLoading, setIsLoading] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const fetchData = async (selection: string) => {
    if (!selection) return
    const onSuccess = (data: any) => {
      setIsLoading(false)
      const words = data.map((d: { word: string }) => d.word)
      setSuggestions(words)
    }
    const data = { word: selection }
    apiCall({ url: 'rhyme', method: 'post', data, onSuccess })
    setIsLoading(true)
  }
  const debouncedFetchData = useCallback(debounce(fetchData, 500), []) // eslint-disable-line react-hooks/exhaustive-deps

  // get word selection
  const editorSelection = useMemo(() => getEditorSelection(editorState), [editorState])
  useEffect(() => {
    debouncedFetchData(editorSelection)
  }, [debouncedFetchData, editorSelection])

  const onRefetch = useCallback(() => {
    debouncedFetchData(editorSelection)
  }, [debouncedFetchData, editorSelection])

  // update title
  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)

  // editor & suggestion rwd
  const [editorWidth, setEditorWidth] = useState(0)
  const { width: windowWidth } = useGetWindowSize()
  useEffect(() => {
    const suggestionWidth = windowWidth >= DESKTOP_BREAKPOINT ? 384 : 64
    setEditorWidth(windowWidth - suggestionWidth)
  }, [windowWidth])

  // mobile swipe
  const mainRef = useRef<HTMLDivElement>(null)
  const [posX, setPosX] = useState<number>(0)
  const startPosX = useRef<number>(0)
  useEffect(() => {
    const main = mainRef.current
    const onTouchStart = (e: TouchEvent) => {
      startPosX.current = e.changedTouches[0].pageX
    }
    const onTouchMove = (e: TouchEvent) => {
      const pageX = e.targetTouches[0].pageX
      const startPageX = startPosX.current
      const deltaX = startPageX - pageX

      if (deltaX > 0) {
        if (Math.abs(deltaX) > 80) setPosX(-300)
      } else {
        if (Math.abs(deltaX) > 80) setPosX(0)
      }
    }
    main.addEventListener('touchmove', onTouchMove)
    main.addEventListener('touchstart', onTouchStart)
    return () => {
      main.removeEventListener('touchmove', onTouchMove)
      main.removeEventListener('touchstart', onTouchStart)
    }
  }, [posX, windowWidth])
  return (
    <div className="font-sans relative">
      <EditorHeader
        id={id}
        title={title}
        editorState={editorState}
        toolbarPlugin={toolbarPlugin}
        setShowWarning={setShowWarning}
      />
      <div
        ref={mainRef}
        className="relative px-4 py-4 md:py-10 md:px-36"
        style={{ width: editorWidth, left: posX, transition: '0.5s' }}
      >
        <TitleInput title={title} showWarning={showWarning} onChange={onTitleChange} />
        <div className="my-2 w-full">
          <Editor
            toolbarPlugin={toolbarPlugin}
            editorState={editorState}
            onChange={setEditorState}
          />
        </div>
        <Suggestion suggestions={suggestions} isLoading={isLoading} onRefetch={onRefetch} />
      </div>
    </div>
  )
}

export default EditorPage
