import { Link } from 'react-router-dom'
import { Modal } from '../index'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { isLoginOpenState, isLoginState, isMenuOpenState } from '../../atoms'

interface Props {
  onClose: any
}

const MenuModal = ({ onClose }: Props) => {
  const isLogin = useRecoilValue(isLoginState)
  const setIsLoginOpen = useSetRecoilState(isLoginOpenState)
  const onLoginClick = () => {
    setIsLoginOpen(true)
    onClose()
  }

  const isMenuOpen = useRecoilValue(isMenuOpenState)
  return (
    <Modal modalType="MenuModal" isOpen={isMenuOpen} onClose={onClose}>
      <div className="w-80 h-screen flex flex-col absolute right-0 px-4 bg-gray-800">
        <Link to="/" className="w-32">
          <img className="mt-8 mb-12" src="/logo.png" alt="logo" />
        </Link>
        <div className="flex-1">
          <div className="w-full grid grid-rows-2 grid-flow-col gap-4 children:flex children:flex-col children:justify-between children:items-center children:bg-gray-900 children:rounded children:p-4">
            <Link to="/editor">
              <img className="lazyload h-16" src="/nav_editor.png" alt="editor" />
              <span className="text-sm">寫一首歌</span>
            </Link>
            <Link to="/list" className="col-span-4">
              <img className="lazyload h-16" src="/nav_list.png" alt="list" />
              <span className="text-sm">歌詞備忘錄</span>
            </Link>
            <Link to="/beat" className="col-span-4">
              <img className="lazyload h-16 mt-2" src="/nav_beat.png" alt="beat" />
              <span className="text-sm">我的節奏</span>
            </Link>
            <span className="cursor-pointer" onClick={onLoginClick}>
              <img className="lazyload h-12 mt-3" src="/home_login.png" alt="login" />
              <span className="text-sm">{isLogin ? '登出' : '登入'}</span>
            </span>
          </div>
        </div>
        <p className="text-gray-500 text-center pb-24 sm:pb-4">© 2021 WORZWRITE</p>
      </div>
    </Modal>
  )
}

export default MenuModal
