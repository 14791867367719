import { Component } from 'react'
import {
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
} from '@draft-js-plugins/buttons'

class HeadlinesPicker extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('click', this.onWindowClick)
    })
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick)
  }

  onWindowClick = () =>
    // Call `onOverrideContent` again with `undefined`
    // so the toolbar can show its regular content again.
    // @ts-ignore
    this.props.onOverrideContent(undefined)

  render() {
    const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton]
    return (
      <div className="flex">
        {buttons.map((Button, i) => (
          <div key={`headline-${i}`} className="px-2">
            {/* @ts-ignore */}
            <Button key={i} {...this.props} />
          </div>
        ))}
      </div>
    )
  }
}

class HeadlinesButton extends Component {
  onClick = () =>
    // A button can call `onOverrideContent` to replace the content
    // of the toolbar. This can be useful for displaying sub
    // menus or requesting additional information from the user.
    // @ts-ignore
    this.props.onOverrideContent(HeadlinesPicker)

  render() {
    return (
      <div className="px-2">
        <button onClick={this.onClick}>H</button>
      </div>
    )
  }
}

export default HeadlinesButton
