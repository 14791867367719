import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <div className="page error-page bg-darkgrey flex flex-col justify-center items-center h-screen -mt-28">
      <h2>Error 404</h2>
      <p>
        Please go back to{' '}
        <Link to="/" className="font-white underline">
          Home Page
        </Link>
      </p>
    </div>
  )
}

export default ErrorPage
