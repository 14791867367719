import { useEffect, useState, useMemo, useRef } from 'react'
import { useCallbackRef } from 'use-callback-ref'
import { useRecoilValue } from 'recoil'
import { Howl, Howler } from 'howler'
import { Range } from 'rc-slider'
import { musicFileState } from '../atoms'
import { playerLottie } from '../constant/lottie'
import lottieWeb from 'lottie-web'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

let playAnimation: any
let raf: any
const calculateTime = (secs: any) => {
  const minutes = Math.floor(secs / 60)
  const seconds = Math.floor(secs % 60)
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
  return `${minutes}:${returnedSeconds}`
}

// slider = progress bar
const Player = () => {
  // to trigger rerender by `update`
  const [, update] = useState(null)
  const loopEnabledRef = useCallbackRef(false, () => update({}))
  const markerValueRef = useCallbackRef([0, 100], () => update({}))
  const { src, title } = useRecoilValue(musicFileState)
  const sound = useMemo(
    () =>
      new Howl({
        src: [src],
        loop: true,
        html5: true, // some iphone could not get the sound
        onload: () => {
          const duration = sound.duration()
          setDuration(calculateTime(duration))
          setSliderMax(Math.floor(duration))
          setMarkerMax(duration)
          markerValueRef.current = [0, duration]
        },
        onplay: () => {
          playAnimation.playSegments([14, 27], true)
        },
        onpause: () => {
          playAnimation.playSegments([0, 14], true)
          cancelAnimationFrame(raf)
        },
        onstop: () => {
          playAnimation.playSegments([0, 14], true)
          cancelAnimationFrame(raf)
          // workaround: force currentTime & slider to reset
          setSliderValue(0)
          setCurrentTime('0:00')
        },
      }),
    [src, markerValueRef],
  )
  const [duration, setDuration] = useState('0:00')
  const [currentTime, setCurrentTime] = useState('0:00')
  const [sliderMax, setSliderMax] = useState(100)
  const [sliderValue, setSliderValue] = useState(0)
  const [markerMax, setMarkerMax] = useState(100)
  const [showPlayer, setShowPlayer] = useState(true)
  const playButton = useRef(null)
  // toggle lottie animation
  useEffect(() => {
    playAnimation = lottieWeb.loadAnimation({
      container: playButton.current,
      animationData: playerLottie,
      renderer: 'svg',
      loop: false,
      autoplay: false,
    })
    playAnimation.goToAndStop(14, true)
  }, [])
  const onTimeUpdate = () => {
    const seek = sound.seek()
    const currentTime = typeof seek === 'number' ? seek : 0
    setSliderValue(currentTime)
    setCurrentTime(calculateTime(currentTime))
    // loop between markers
    if (
      loopEnabledRef.current &&
      (currentTime > markerValueRef.current[1] || currentTime < markerValueRef.current[0])
    ) {
      sound.seek(markerValueRef.current[0])
    }
    const timer = setTimeout(() => {
      if (sound.playing()) {
        raf = requestAnimationFrame(() => onTimeUpdate())
        clearTimeout(timer)
      }
    }, 150)
  }
  useEffect(() => {
    Howler.stop()
  }, [sound])
  const onPlay = () => {
    if (!src) return
    if (sound.playing()) {
      sound.pause()
    } else {
      sound.play()
      onTimeUpdate()
    }
    // start playing on startTime marker if loopEnabled
    if (loopEnabledRef.current) sound.seek(markerValueRef.current[0])
  }
  const onSliderChange = (value: any) => {
    if (!src) return
    value = Number(value)
    setSliderValue(value)
    setCurrentTime(calculateTime(value))
    sound.seek(value)
  }
  const onMarkerChange = (value: any) => {
    markerValueRef.current = value
    // sync marker startTime with ctx.currentTime
    onSliderChange(value[0])
  }
  const onLoopEnabledChange = () => {
    loopEnabledRef.current = !loopEnabledRef.current
  }
  const onShowPlayer = () => {
    setShowPlayer(!showPlayer)
  }
  const playerClasses = classNames(
    { 'translate-y-0': showPlayer, 'translate-y-28': !showPlayer },
    'player transform transition duration-500 ease-in-out fixed bottom-0 w-full bg-gray-800 select-none',
  )
  const arrowClasses = classNames(
    { 'rotate-180': showPlayer, 'rotate-0': !showPlayer },
    'transform transition-transform duration-500 ease-in-out h-6 w-6 fill-current',
  )
  const loopClasses = classNames(
    { active: loopEnabledRef.current },
    'px-2 cursor-pointer loop-icon',
  )

  const location = useLocation()
  if (location.pathname === '/list') return null
  return (
    <div className={playerClasses}>
      <div
        className="absolute left-1/2 transform -translate-x-1/2 -top-10 bg-gray-800 px-4 py-2 rounded-t cursor-pointer"
        onClick={onShowPlayer}
      >
        <svg className={arrowClasses} viewBox="0 0 20 20">
          <path d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z" />
        </svg>
      </div>
      <div>
        <div className="flex mt-4 justify-center">{title}</div>
        <div className="flex items-center">
          <div className="w-16 text-center">{currentTime}</div>
          {src && (
            <>
              <Range
                className="flex-1"
                disabled={!loopEnabledRef.current}
                step={0.1}
                allowCross={false}
                max={markerMax}
                value={markerValueRef.current}
                onChange={onMarkerChange}
              />
            </>
          )}
          <div className="w-16 text-center">{duration}</div>
        </div>
        <div className="flex items-center">
          <div className="flex justify-center w-16">
            <button id="play-icon" ref={playButton} className="w-12 p-2" onClick={onPlay} />
          </div>
          <input
            className="flex-1 cursor-pointer"
            type="range"
            step={0.1}
            max={sliderMax}
            onChange={(e) => onSliderChange(e.target.value)}
            value={sliderValue}
          />
          <div className="flex w-16 items-center justify-center">
            <img onClick={onLoopEnabledChange} className={loopClasses} src="/loop.svg" alt="loop" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Player
