import { Dispatch, SetStateAction, useRef } from 'react'
import { EditorState } from 'draft-js'
import { ToolbarPlugin } from './types'
import Editor from '@draft-js-plugins/editor'
import { styleMap } from './util'
import 'draft-js/dist/Draft.css'

interface Props {
  onChange: Dispatch<SetStateAction<EditorState>>
  editorState: EditorState
  toolbarPlugin: ToolbarPlugin
}

function RhymeEditor({ onChange, editorState, toolbarPlugin }: Props) {
  const editor = useRef<any>(null)
  function focusEditor() {
    editor.current.focus()
  }
  return (
    <div className="Editor flex-0-auto md:flex-none w-screen md:w-2/3 rounded">
      <div className="p-4 cursor-text" onClick={focusEditor}>
        <div className="font-medium overflow-y-auto h-96">
          <Editor
            ref={editor}
            customStyleMap={styleMap}
            editorState={editorState}
            plugins={[toolbarPlugin]}
            onChange={onChange}
            placeholder="寫些什麼..."
          />
        </div>
      </div>
    </div>
  )
}

export default RhymeEditor
