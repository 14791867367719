import { useRecoilState } from 'recoil'
import { FileDropZone } from '../component'
import { musicFileState } from '../atoms'
import { musicFileMaxSize, defaultMusicFiles } from '../constant'

const BeatPage = () => {
  const [, setFile] = useRecoilState(musicFileState)
  const loadMusicFile = async (d: any) => {
    const { url, artist, name } = d
    const title = `${artist} - ${name}`
    setFile({ src: `songs/${url}`, title })
  }
  return (
    <div className="page flex flex-col items-center">
      <FileDropZone accept="audio/*" maxSize={musicFileMaxSize} onChange={setFile} />
      <div className="w-full md:w-2/3">
        {defaultMusicFiles.map((d: any) => {
          return (
            <div
              className="px-4 py-4 flex items-center cursor-pointer rounded hover:bg-gray-700 select-none"
              key={d.name}
              onClick={() => loadMusicFile(d)}
            >
              <div className="flex-1">{d.artist}</div>
              <div>{d.name}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BeatPage
