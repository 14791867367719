import { useMemo } from 'react'
import { useGetWindowSize } from '../../hooks'
import { sliceArray } from './utils'
import { COLUMN_NUMBER, DESKTOP_BREAKPOINT } from './constant'
import DesktopSuggestion from './DesktopSuggestion'
import MobileSuggestion from './MobileSuggestion'

interface Props {
  isLoading: boolean
  suggestions: string[]
  onRefetch(): void
}

const Suggestion = ({ suggestions, isLoading, onRefetch }: Props) => {
  const windowSize = useGetWindowSize()
  const suggestionRows = useMemo<string[][]>(() => {
    return sliceArray<string>(suggestions, COLUMN_NUMBER)
  }, [suggestions])

  return windowSize.width > DESKTOP_BREAKPOINT ? (
    <DesktopSuggestion rows={suggestionRows} isLoading={isLoading} onRefetch={onRefetch} />
  ) : (
    <MobileSuggestion isLoading={isLoading} rows={suggestionRows} onRefetch={onRefetch} />
  )
}
export default Suggestion
