import Loader from '../../component/loading/Loader'

interface Props {
  isLoading: boolean
  rows: string[][]
  onRefetch(): void
}

const MobileSuggestion = ({ rows, isLoading, onRefetch }: Props) => {
  return (
    <div
      className="absolute -right-96 top-0 w-96 h-full bg-darkblue flex justify-center items-start pt-6"
      style={{ minHeight: 'calc(100vh - 98px)' }}
    >
      {rows.length > 0 ? (
        <div className="p-2">
          <div className="flex flex-col justify-center" style={{ width: 320, height: 320 }}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {rows.map((row, rowIndex) => {
                  return (
                    <div key={`row-${rowIndex}`}>
                      {row.map((el, elIndex) => {
                        return (
                          <div
                            key={`row${rowIndex}-${elIndex}`}
                            className="inline-flex justify-center items-center w-16 h-16"
                          >
                            {el}
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </>
            )}
          </div>
          <img
            className="mt-10 mb-20 mx-auto cursor-pointer"
            src="/img/refresh_icon.svg"
            alt="refresh icon"
            onClick={onRefetch}
          />
        </div>
      ) : (
        <div>選取文字 即可查看韻腳文字</div>
      )}
    </div>
  )
}

export default MobileSuggestion
