import axios from 'axios'
import { API_HOST } from '../constant'

export const apiCall = ({
  url = '/',
  method = 'get',
  data = {},
  contentType = '',
  onSuccess = (d: any) => {},
  onError = () => {},
}) => {
  try {
    url = `${API_HOST}/${url}`
    const token = getToken()
    const _token = token ? { Authorization: `Bearer ${token}` } : {}
    const _contentType = contentType ? { 'Content-Type': contentType } : {}
    const headers = { ..._token, ..._contentType }
    // @ts-ignore
    axios({ method, url, data, headers })
      .then((res) => {
        if (res.status === 200) {
          onSuccess(res.data.data)
          const { token } = res.headers
          if (token) setToken(token)
        } else console.log(res.status, res)
      })
      .catch((err) => {
        console.error(err)
        onError()
        if (err.response) {
          const { status } = err.response
          if (status === 401 && getToken()) logout()
        }
      })
  } catch (err) {
    console.error(err)
  }
}

export const logout = (callback?: any) => {
  const onSuccess = () => {
    callback && callback()
  }
  try {
    apiCall({ url: 'logout', method: 'post', onSuccess })
  } catch {
    console.warn('skip logout error')
  } finally {
    setToken('')
    window.location.reload(true)
  }
}

export const getToken = () => localStorage.getItem('at') || ''
export const setToken = (token: string) => {
  localStorage.setItem('at', token)
}
