import { Modal } from './index'

interface Props {
  title: string
  isOpen: boolean
  setIsOpen: any
  onConfirm: any
}

const ConfirmDeleteModal = ({ title, isOpen, setIsOpen, onConfirm }: Props) => {
  const onClose = () => {
    setIsOpen(false)
  }
  return (
    <Modal modalType="ConfirmDeleteModal" isOpen={isOpen} onClose={onClose}>
      <div className="text-white">
        <div className="absolute top-4 right-4 cursor-pointer" onClick={onClose}>
          <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
          </svg>
        </div>
        <img
          className="absolute -top-10 left-1/2 transform -translate-x-1/2 w-40 z-10"
          src="/home_editor.png"
          alt=""
        />
        <div className="w-full px-4 mt-16 text-center">{title} 確定要刪除嗎？</div>
        <div className="flex justify-center my-8">
          <div onClick={onClose} className="bg-gray-600 px-5 py-3 rounded-full cursor-pointer">
            取消
          </div>
          <div
            className="bg-red-600 px-5 py-3 ml-4 rounded-full cursor-pointer"
            onClick={onConfirm}
          >
            確定刪除
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmDeleteModal
