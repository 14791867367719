import { atom } from 'recoil'
import { getToken } from './util'

export const musicFileState = atom({
  key: 'musicFileState',
  default: {
    src: 'songs/5.mp3',
    title: 'GreenTedBeat TRAP 120BPM',
  },
})

export const isLoginOpenState = atom({
  key: 'isLoginOpenState',
  default: false,
})

export const isLoginState = atom({
  key: 'isLoginState',
  default: getToken() !== '',
})

export const isMenuOpenState = atom({
  key: 'isMenuOpenState',
  default: false,
})
