let timer: any

export const debounce = (func: any, delay = 300) => {
  let prevArg: any
  return (arg: any) => {
    if (prevArg === arg) return
    prevArg = arg
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(arg)
      prevArg = null
      timer = null
    }, delay)
  }
}

export const readFile = (setFile: any, file: any) => {
  const reader = new FileReader()
  reader.onabort = () => console.log('file reading was aborted')
  reader.onerror = () => console.log('file reading has failed')
  reader.onload = (e: any) => {
    const { result } = e.target
    setFile({ src: result, title: file.name })
  }
  reader.readAsDataURL(file)
}
