export const musicFileMaxSize = 10 // MB
export const defaultMusicFiles = [
  { artist: 'GreenTedBeat', name: 'HIPHOP 88BPM', url: '1.mp3' },
  { artist: 'GreenTedBeat', name: 'HIPHOP 95BPM', url: '2.mp3' },
  { artist: 'GreenTedBeat', name: 'HIPHOP 157BPM', url: '3.mp3' },
  { artist: 'GreenTedBeat', name: 'RNB 120BPM', url: '4.mp3' },
  { artist: 'GreenTedBeat', name: 'TRAP 120BPM', url: '5.mp3' },
]

export const API_HOST = process.env.REACT_APP_API_HOST || 'http://localhost:8000/api'

export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
