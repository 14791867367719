import classNames from 'classnames'
import { useSetRecoilState } from 'recoil'
import { isMenuOpenState } from '../../atoms'

interface Props {
  isMenuOpen: boolean
}

const MenuButton = ({ isMenuOpen }: Props) => {
  const setIsMenuOpen = useSetRecoilState(isMenuOpenState)
  const toggleMenu = () => setIsMenuOpen((prevValue) => !prevValue)
  const buttonStyle = classNames('menu-button w-12 h-12 fixed top-5 right-3 cursor-pointer')
  const topLineStyle = classNames(
    'w-7 h-0.5 m-2 bg-white transition-rotate duration-300 transform',
    {
      'rotate-45 translate-y-1': isMenuOpen,
    },
  )
  const middleLineStyle = classNames('w-7 h-0.5 m-2 bg-white transition-opacity duration-300', {
    'opacity-0': isMenuOpen,
  })
  const bottomLineStyle = classNames(
    'w-7 h-0.5 m-2 bg-white transition-rotate duration-300 transform',
    { '-rotate-45 -translate-y-4': isMenuOpen },
  )
  return (
    <div className={buttonStyle} onClick={toggleMenu}>
      <div className={topLineStyle}></div>
      <div className={middleLineStyle}></div>
      <div className={bottomLineStyle}></div>
    </div>
  )
}

export default MenuButton
