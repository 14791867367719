import ReactModal from 'react-modal'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

interface Props {
  isOpen: boolean
  modalType: 'MenuModal' | 'LoginModal' | 'ConfirmDeleteModal'
  onClose: any
  children: React.ReactNode
}

ReactModal.setAppElement('#root')

const Modal = ({ isOpen, modalType, onClose, children }: Props) => {
  const history = useHistory()
  useEffect(() => {
    history.listen(() => onClose())
  }, [history, onClose])
  const modalContentClass = {
    base: `${modalType}__Content`,
    afterOpen: `${modalType}__Content--after-open`,
    beforeClose: `${modalType}__Content--before-close`,
  }
  const modalOverlayClass = {
    base: `${modalType}__Overlay`,
    afterOpen: `${modalType}__Overlay--after-open`,
    beforeClose: `${modalType}__Overlay--before-close`,
  }
  return (
    <ReactModal
      className={modalContentClass}
      overlayClassName={modalOverlayClass}
      isOpen={isOpen}
      closeTimeoutMS={1000}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
    >
      {children}
    </ReactModal>
  )
}

export default Modal
