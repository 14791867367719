interface Props {
  isShow: boolean
}

const LoadingScreen = ({ isShow }: Props) => {
  const staticClasses =
    'loading-screen flex flex-column justify-center items-center h-screen w-screen fixed top-0 left-0 bg-gray-800 transition duration-500 ease-in-out'
  return (
    <div
      className={`${staticClasses} ${
        isShow ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
      }`}
    >
      <div>
        <img className="w-36 h-auto" src="/logo.png" alt="logo" />
        <div className="flex justify-center items-center mt-5">
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
        </div>
      </div>
    </div>
  )
}

export default LoadingScreen
