import { Component } from 'react'
import { createInlineStyleButton } from '@draft-js-plugins/buttons'
import { styleMap } from './util'

const FontBlackButton = createInlineStyleButton({
  style: 'FONT-MINT',
  children: 'mint',
})

const FontWhiteButton = createInlineStyleButton({
  style: 'FONT-WHITE',
  children: <span style={{ color: styleMap['FONT-WHITE'].color }}>white</span>,
})

const FontYellowButton = createInlineStyleButton({
  style: 'FONT-YELLOW',
  children: <span style={{ color: styleMap['FONT-YELLOW'].color }}>yellow</span>,
})

class FontColorsPicker extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('click', this.onWindowClick)
    })
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick)
  }

  onWindowClick = () =>
    // Call `onOverrideContent` again with `undefined`
    // so the toolbar can show its regular content again.
    // @ts-ignore
    this.props.onOverrideContent(undefined)

  render() {
    const buttons = [FontBlackButton, FontWhiteButton, FontYellowButton]
    return (
      <div className="flex">
        {buttons.map((Button, i) => (
          <div key={`font-${i}`} className="px-2">
            {/* @ts-ignore */}
            <Button key={i} {...this.props} />
          </div>
        ))}
      </div>
    )
  }
}

class FontColorsButton extends Component {
  onClick = () =>
    // A button can call `onOverrideContent` to replace the content
    // of the toolbar. This can be useful for displaying sub
    // menus or requesting additional information from the user.
    // @ts-ignore
    this.props.onOverrideContent(FontColorsPicker)

  render() {
    return (
      <div className="px-2">
        <button onClick={this.onClick}>C</button>
      </div>
    )
  }
}

export default FontColorsButton
