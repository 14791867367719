import { Modal } from './index'
import { Link } from 'react-router-dom'
import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'
import { apiCall, getToken, setToken, logout, toastError, toastSuccess } from '../util'
import { FB_APP_ID, GOOGLE_CLIENT_ID } from '../constant'

interface Props {
  isOpen: boolean
  isLogin: boolean
  setIsOpen: any
  setIsLogin: any
}

const LoginModal = ({ isOpen, isLogin, setIsOpen, setIsLogin }: Props) => {
  const login = (type: string, accessToken: string, name: string, email: any) => {
    const onSuccess = (data: any) => {
      const { token } = data
      setIsOpen(false)
      setToken(token)
      setIsLogin(true)
      toastSuccess('登入成功🙌🏻')
    }
    const onError = () => toastError('登入失敗😠')
    const data = { name, accessToken, email }
    apiCall({
      url: `login/${type}`,
      method: 'post',
      data,
      onSuccess,
      onError,
    })
  }
  const responseFacebook = (response: any) => {
    // error handling
    const { accessToken, name, email } = response
    login('facebook', accessToken, name, email)
  }
  const renderFacebookLogin = () => (
    <FacebookLogin
      appId={FB_APP_ID}
      autoLoad={false}
      fields="name,email"
      scope="public_profile"
      callback={responseFacebook}
      disableMobileRedirect={true}
      textButton=""
      icon="fa-facebook"
    />
  )
  const responseGoogle = (response: any) => {
    const { tokenId, profileObj } = response
    const { name, email } = profileObj
    login('google', tokenId, name, email)
  }
  const renderGoogleLogin = () => (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      buttonText=""
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={'single_host_origin'}
    />
  )
  const onLogout = () => {
    const callback = () => setIsOpen(false)
    logout(callback)
  }
  const onClose = () => {
    setIsOpen(false)
  }
  return (
    <Modal modalType="LoginModal" isOpen={isOpen} onClose={onClose}>
      <div className="absolute top-4 right-4 cursor-pointer" onClick={onClose}>
        <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
          <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
        </svg>
      </div>
      <div className="pt-6 text-4xl text-center select-none">Login</div>
      <div className="h-48 flex justify-center items-center">
        <img src="/open_book.png" alt="" />
      </div>
      {!getToken() && (
        <div className="flex justify-evenly">
          {renderFacebookLogin()}
          {renderGoogleLogin()}
        </div>
      )}
      {isLogin && (
        <div onClick={onLogout} className="py-4 text-center cursor-pointer select-none">
          Logout
        </div>
      )}
      <div className="my-4 text-center">
        <Link className="select-none" to="policy">
          隱私政策
        </Link>
        <span className="mx-2">|</span>
        <Link className="select-none" to="terms">
          用戶守則
        </Link>
      </div>
    </Modal>
  )
}

export default LoginModal
