const Loader = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="spinner rounded-full mb-3" />
      資料載入中
    </div>
  )
}

export default Loader
