import MenuModal from './MenuModal'
import MenuButton from './MenuButton'
import { useRecoilState } from 'recoil'
import { isMenuOpenState } from '../../atoms'

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(isMenuOpenState)
  return (
    <>
      <MenuButton isMenuOpen={isMenuOpen} />
      <MenuModal onClose={() => setIsMenuOpen(false)} />
    </>
  )
}
export default Menu
