// @ts-nocheck
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { readFile } from '../util'

const FileDropzone = ({ onChange, accept, maxSize = 10 }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0]
        readFile(onChange, file)
        return
      }
    },
    [onChange],
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxSize: maxSize * 1048576, // mb to bytes
  })
  const dropzoneClass =
    'upload-files text-center text-white py-10 bg-gray-800 select-none cursor-pointer'
  return (
    <div className="w-11/12" {...getRootProps()}>
      <input className="upload-wrapper" {...getInputProps()} />
      {isDragActive ? (
        <p className={dropzoneClass}>拖曳檔案至此</p>
      ) : (
        <p className={dropzoneClass}>拖曳檔案至此，或點擊此選擇檔案</p>
      )}
      <div className="select-none m-4">檔案大小須小於{maxSize}MB</div>
    </div>
  )
}

export default FileDropzone
