import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom'
import 'lazysizes'
import HomePage from './HomePage'
import ListPage from './ListPage'
import EditorPage from './EditorPage'
import BeatPage from './BeatPage'
import PolicyPage from './PolicyPage'
import TermsPage from './TermsPage'
import ErrorPage from './ErrorPage'
import { Player, Header, ErrorScreen, LoginModal, Menu } from '../component'
import { ErrorBoundary } from 'react-error-boundary'
import { useRecoilState } from 'recoil'
import { isLoginState, isLoginOpenState } from '../atoms'

const MainRoute = () => {
  const [isLogin, setIsLogin] = useRecoilState(isLoginState)
  const [isLoginOpen, setIsLoginOpen] = useRecoilState(isLoginOpenState)
  return (
    <Router>
      <Header />
      <Menu />
      <AppRoutes />
      <Player />
      <LoginModal
        isOpen={isLoginOpen}
        isLogin={isLogin}
        setIsOpen={setIsLoginOpen}
        setIsLogin={setIsLogin}
      />
    </Router>
  )
}

const routes = [
  { path: '/', Component: HomePage, exact: true },
  { path: '/list', Component: ListPage },
  { path: '/editor/:id', Component: EditorPage },
  { path: '/editor', Component: EditorPage },
  { path: '/beat', Component: BeatPage },
  { path: '/policy', Component: PolicyPage },
  { path: '/terms', Component: TermsPage },
  { path: '*', Component: ErrorPage },
]

const AppRoutes = () => {
  const location = useLocation()
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} timeout={600} classNames="fade">
        <Switch location={location}>
          {routes.map(({ path, Component, exact }) => (
            <Route key={path} path={path} exact={exact}>
              <ErrorBoundary FallbackComponent={ErrorScreen}>
                <Component />
              </ErrorBoundary>
            </Route>
          ))}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default MainRoute
