import { useState, useEffect, useRef } from 'react'
import { useHistory, Link } from 'react-router-dom'
import Fuse from 'fuse.js'
import { apiCall, getToken } from '../util'
import { ConfirmDeleteModal } from '../component'
import { useGetWindowSize } from '../hooks'
import { DESKTOP_BREAKPOINT } from '../component/suggestion/constant'

//const data = [
//{
//id: 1,
//user_id: 6,
//title: 'list12',
//created_at: '2021-10-29',
//},
//{
//id: 2,
//user_id: 6,
//title: 'xxxxxxxxxxyyyyyxxxxxx',
//created_at: '2021-10-29',
//},
//]

const ListPage = () => {
  const history = useHistory()
  const [query, setQuery] = useState('')
  const [list, setList] = useState([])
  useEffect(() => {
    const onSuccess = (l: any) => setList(l)
    apiCall({ url: 'notebook', onSuccess })
  }, [])
  const fuse = new Fuse(list, { keys: ['title', 'created_at'] })
  const onSearch = (e: any) => setQuery(e.target.value)
  const results = fuse.search(query).map((res) => res.item)
  const filteredList = query ? results : list
  const onClick = (id: number) => {
    history.push(`/editor/${id}`)
  }
  const onRemove = (id: number) => {
    const onSuccess = () => {
      const filteredList = list.filter((l) => l.id !== id)
      setList(filteredList)
    }
    apiCall({ url: `notebook/${id}`, method: 'delete', onSuccess })
  }
  const onCreate = () => {
    history.push('/editor')
  }

  const { width: windowWidth } = useGetWindowSize()
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    if (windowWidth >= DESKTOP_BREAKPOINT) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }, [windowWidth])
  return (
    <div className="page flex flex-col md:mx-64 mx-4 items-center select-none text-white">
      <div className="fixed top-0 px-6 py-4 w-full bg-gray-800 flex flex-col sm:flex-row justify-between items-center z-10">
        <Link to="/">
          <img className="w-28" src="logo.png" alt="WorzWrite" />
        </Link>
        <div className="flex justify-center items-center mt-4 sm:mt-0">
          <input
            className="placeholder-gray-400 text-gray-100 bg-gray-900 flex-1 sm:flex-none rounded outline-none border-none w-56 px-6 py-2 h-12 rounded-3xl"
            value={query}
            placeholder="搜尋歌詞本"
            onChange={onSearch}
          />
          <img src="/search.svg" alt="" className="ml-4 h-12" />
        </div>
        <div className="w-14" />
      </div>
      <div className="my-4 sm:my-6 w-full flex items-center justify-center">
        <div className="flex flex-1 flex-col">
          <div className="text-2xl font-medium text-center sm:text-left">歌詞本</div>
          {filteredList.length !== 0 && (
            <div className="mt-2 text-gray-400">共 {filteredList.length} 本歌詞</div>
          )}
        </div>
        {!isMobile && filteredList.length > 0 && (
          <div
            className="flex justify-center items-center bg-gray-700 w-32 h-12 rounded-full cursor-pointer"
            onClick={onCreate}
          >
            + 寫新歌詞
          </div>
        )}
      </div>
      <div className="w-full">
        {!getToken() && <div className="text-center my-3 text-gray-400">登入即可儲存</div>}
        {getToken() && !filteredList.length && (
          <div className="w-full flex flex-col items-center">
            <div className="mt-5 text-gray-400">無歌詞本</div>
            <div
              className="flex justify-center items-center mt-5 bg-gray-700 w-32 h-12 rounded-full cursor-pointer"
              onClick={onCreate}
            >
              + 寫新歌詞
            </div>
          </div>
        )}
        <div className="flex flex-wrap justify-center">
          {filteredList.map((d: any, i) => (
            <ListItem key={d + i} {...d} onRemove={onRemove} onClick={onClick} />
          ))}
        </div>
      </div>
      {isMobile && filteredList.length > 0 && (
        <div className="flex justify-center items-center fixed left-0 right-0 bottom-0 bg-gradient">
          <div
            className="flex justify-center items-center bg-gray-700 w-32 h-12 mb-5 rounded-full cursor-pointer"
            onClick={onCreate}
          >
            + 寫新歌詞
          </div>
        </div>
      )}
    </div>
  )
}

interface Props {
  id: number
  title: string
  onRemove(id: number): void
  onClick(id: number): void
}

const ListItem = ({ id, title, onRemove, onClick }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const wrapperRef = useRef(null)
  const closeMenu = () => setIsMenuOpen(false)
  useOutsideAlerter(wrapperRef, closeMenu)
  const onConfirm = (e: any) => {
    e.stopPropagation()
    onRemove(id)
    setIsMenuOpen(false)
    setIsModalOpen(false)
  }
  return (
    <div className="relative px-4 py-4 flex flex-col items-center w-36 m-2 rounded bg-gray-800 break-all">
      <ConfirmDeleteModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onConfirm={onConfirm}
        title={title}
      />
      <img
        className="absolute top-4 right-4 cursor-pointer"
        src="/three_dot.svg"
        alt=""
        onClick={() => setIsMenuOpen(true)}
      />
      {isMenuOpen && (
        <div
          className="absolute top-8 -right-6 px-4 py-2 bg-gray-600 z-10 rounded-full cursor-pointer"
          onClick={() => setIsModalOpen(true)}
          ref={wrapperRef}
        >
          刪除歌詞本
        </div>
      )}
      <img
        className="h-24 lazyload cursor-pointer"
        src="/nav_editor.png"
        alt=""
        onClick={() => onClick(id)}
      />
      <div className="cursor-pointer" onClick={() => onClick(id)}>
        {title}
      </div>
    </div>
  )
}

function useOutsideAlerter(ref: any, callback: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

export default ListPage
