import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

const ignorePages = /\/(list|editor)(\/[A-Za-z1-9]*)*/

const Header = () => {
  const [isScroll, setIsScroll] = useState(true)
  const location = useLocation()
  useEffect(() => {
    const onScrollDown = (e: any) => {
      const scrollTarget = e.target.nodeName
      const scrollTop = e.target.scrollTop
      scrollTarget === 'BODY' && scrollTop > 150 ? setIsScroll(false) : setIsScroll(true)
    }
    window.addEventListener('scroll', onScrollDown, true)
    return () => window.removeEventListener('scroll', onScrollDown)
  }, [])
  if (ignorePages.test(location.pathname)) return null
  return (
    <header>
      <div
        className={`${
          isScroll ? 'md:bg-transparent' : 'md:bg-gray-800'
        } navbar h-28 fixed top-0 left-0 right-0 py-8 px-10 bg-gray-800 transition duration-500`}
      >
        <Link to="/">
          <img
            className="w-36 absolute left-1/2 transform -translate-x-1/2"
            src="/logo.png"
            alt="WorzWrite"
          />
        </Link>
      </div>
    </header>
  )
}

export default Header
