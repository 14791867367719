import { RecoilRoot } from 'recoil'
import MainRoute from './page'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <RecoilRoot>
      <div className="App h-full relative">
        <MainRoute />
        <ToastContainer />
      </div>
    </RecoilRoot>
  )
}

export default App
