import { useHistory } from 'react-router'
import { EditorState, convertToRaw } from 'draft-js'

import {
  ItalicButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from '@draft-js-plugins/buttons'
import HeadlinesButton from './HeadlinesButton'
import FontColorsButton from './FontColorsButton'
import { apiCall, toastSuccess, toastError } from '../../util'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { isLoginState, isLoginOpenState } from '../../atoms'
import { Dispatch, SetStateAction } from 'react'
import { ToolbarPlugin } from './types'

interface Props {
  editorState: EditorState
  title: string
  id: string
  toolbarPlugin: ToolbarPlugin
  setShowWarning: Dispatch<SetStateAction<boolean>>
}

const EditorHeader = ({ editorState, toolbarPlugin, title, id, setShowWarning }: Props) => {
  const history = useHistory()
  const isLogin = useRecoilValue(isLoginState)
  const setIsLoginOpen = useSetRecoilState(isLoginOpenState)
  const onSave = () => {
    const content = JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    if (!isLogin) return setIsLoginOpen(true)
    if (!title) return setShowWarning(true)
    if (content.length > 5000) return toastError('禁止超過五千字🤗')
    const data = {
      content,
      title,
    }
    const onError = () => toastError('存檔失敗🥲')
    if (id) {
      toastSuccess('存檔成功👌🏻')
      apiCall({ url: `notebook/${id}`, method: 'put', data, onError })
    } else {
      const onSuccess = (data: any) => {
        history.push(`/editor/${data.id}`)
        toastSuccess('存檔成功👌🏻')
      }
      apiCall({ url: 'notebook', method: 'post', data, onSuccess, onError })
    }
  }
  return (
    <div className="editor-header px-6 py-4 w-full bg-gray-800 flex justify-between items-center">
      <img className="hidden md:block" src="/img/editor_icon.svg" alt="editor page icon" />
      <div className="flex-1 md:divide-y divide-gray-600">
        <span className="text-gray-400 cursor-pointer" onClick={onSave}>
          儲存
        </span>
        <div className="mt-4 md:pt-4 toolbar-wrapper">
          <toolbarPlugin.Toolbar>
            {
              // may be use React.Fragment instead of div to improve perfomance after React 16
              (externalProps) => (
                <div className="flex toolbar">
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  {/* @ts-ignore */}
                  <HeadlinesButton {...externalProps} />
                  <UnorderedListButton {...externalProps} />
                  <OrderedListButton {...externalProps} />
                  <FontColorsButton {...externalProps} />
                </div>
              )
            }
          </toolbarPlugin.Toolbar>
        </div>
      </div>
      <div className="w-16" />
    </div>
  )
}
export default EditorHeader
