import { EditorState } from 'draft-js'

export const styleMap = {
  'FONT-MINT': {
    color: 'rgb(194, 228, 209)',
  },
  'FONT-WHITE': {
    color: '#eeeeee',
  },
  'FONT-YELLOW': {
    color: '#e9c46a',
  },
}

export const getEditorSelection = (editorState: EditorState) => {
  const selection = editorState.getSelection()
  const anchorKey = selection.getAnchorKey()
  const currentContent = editorState.getCurrentContent()
  const currentContentBlock = currentContent.getBlockForKey(anchorKey)
  const selectionStart = selection.getStartOffset()
  const selectionEnd = selection.getEndOffset()
  const selectedText = currentContentBlock.getText().slice(selectionStart, selectionEnd)
  return selectedText
}
