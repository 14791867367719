import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { LoadingScreen } from '../component'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { isLoginState, isLoginOpenState } from '../atoms'

const HomePage = ({ setIsLoading }: any) => {
  const history = useHistory()
  const setIsLoginOpen = useSetRecoilState(isLoginOpenState)
  const isLogin = useRecoilValue(isLoginState)
  const onLoginClick = () => setIsLoginOpen(true)
  const [backgroundImage, setBackgroundImage] = useState(null)
  useEffect(() => {
    const image = new Image()
    const backgroundUrl = isLogin ? '/hero_landscape.png' : '/hero_landscape_dark.png'
    image.src = backgroundUrl
    image.onload = () => {
      setBackgroundImage(`url(${backgroundUrl})`)
      setTimeout(() => setIsLoading(false), 500)
    }
  }, [isLogin, setIsLoading])
  return (
    <div>
      <div className="flex justify-center items-center">
        <div
          className="hero-img absolute top-0 h-full bg-cover bg-center bg-no-repeat lazyload"
          style={{ backgroundImage }}
        />
        <img
          className="home-login absolute w-40 cursor-pointer"
          onClick={onLoginClick}
          src="/home_login.png"
          alt="login"
        />
        <img
          className="home-beat absolute cursor-pointer"
          onClick={() => history.push('/beat')}
          src="/home_beat.png"
          alt="beat"
        />
        <img
          className="home-list absolute cursor-pointer"
          onClick={() => history.push('/list')}
          src="/home_list.png"
          alt="list"
        />
        <img
          className="home-editor absolute cursor-pointer"
          onClick={() => history.push('/editor')}
          src="/home_editor.png"
          alt="editor"
        />
      </div>
    </div>
  )
}

const HomePageWithLoader = () => {
  const [isLoading, setIsLoading] = useState(true)
  return (
    <>
      <HomePage setIsLoading={setIsLoading} />
      <LoadingScreen isShow={isLoading} />
    </>
  )
}

export default HomePageWithLoader
