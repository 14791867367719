import { ChangeEvent } from 'react'

interface Props {
  title: string
  showWarning: boolean
  onChange(e: ChangeEvent<HTMLInputElement>): void
}

const TitleInput = ({ title, showWarning, onChange }: Props) => {
  return (
    <div className={`relative p-2 rounded bg-darkblue ${showWarning && 'border border-red-700'}`}>
      <input placeholder="建立標題" value={title} onChange={onChange} />
      {showWarning && <span className="absolute right-2 top-2 text-red-700">請輸入標題</span>}
    </div>
  )
}
export default TitleInput
