import { Link } from 'react-router-dom'

function ErrorScreen() {
  return (
    <div className="bg-darkgrey flex flex-col justify-center items-center h-screen">
      <h2>Some error happened</h2>
      <p>
        Please go back to{' '}
        <Link to="/" className="font-white underline">
          Home Page
        </Link>{' '}
        or just{' '}
        <span
          onClick={() => window.location.reload()}
          className="font-white underline cursor-pointer"
        >
          Refresh Page
        </span>
      </p>
    </div>
  )
}

export default ErrorScreen
